import DataDefinition from "../DataDefinition";

class SongInfo extends DataDefinition {
    id: number;
    name: string;
    authorName: string;
    coverUrl: string;
    musicUrl: string;
    lyrics: string;

    constructor({
        id = 0,
        name = "",
        authorName = "",
        coverUrl = "",
        musicUrl = "",
        lyrics = ""
    }: Partial<SongInfo> = {}) {
        super();
        this.allowedFields = ['id', 'name', 'authorName', 'coverUrl', 'musicUrl', 'lyrics'];
        this.id = id;
        this.name = name;
        this.authorName = authorName;
        this.coverUrl = coverUrl;
        this.musicUrl = musicUrl;
        this.lyrics = lyrics;
    }
}

export default SongInfo;