import { useState } from 'react';
import YinFuIcon from '@/assets/img/yinfu.svg';
import styles from './genCustomMusic.module.less';
import MagicWand from '../MagicWand/MagicWand';
import { Button, message } from 'antd';
import MultimediaAPI from '@/api/multimedia';
import { createSong, musicGenCustom, addSongToAlbum } from '@/api/playgroundMusic';
import LLMCallRequestData from '@/base/LLMCallRequestData';
import { userInfoStore } from '@/store/userInfoStore';
import { requestLLMCallV2 } from '@/services/requestLLMCall';

interface GenCustomMusicProps {
    userId: number;
    getAlbumList: () => void;
};

const GenCustomMusic: React.FC<GenCustomMusicProps>= ({ userId, getAlbumList }) => {
    const vendor = import.meta.env.VITE_GEN_MUSIC_VENDOR;
    const api = new MultimediaAPI();
    const [songTitle, setSongTitle] = useState("");
    const [songLyric, setSongLyric] = useState("");
    const [songStyle, setSongStyle] = useState("");
    const [apiStatus, setApiStatus] = useState(0); // 0: not started, 1: generating,
    //将生成的歌曲信息存入数据库
    const saveSong = async (name: string, lyrics: string, musicUrl: string, 
                    coverUrl: string, style: string) => {
        try {
            const songData = {
                id: 0,
                name: name,
                author_id: userId,
                lyrics: lyrics,
                music_url: musicUrl,
                cover_url: coverUrl,
                tags: style,
                play_count: 0,
                like_count: 0,
                update_time: 0
            };

            // 保存歌曲  
            const songResponse: any = await createSong(songData);
            if (songResponse.status === 'success') {
                console.log('save song success:', songResponse);

                // 将歌曲添加到专辑  
                const albumResponse: any = await addSongToAlbum(userId, songResponse.data.id);
                if (albumResponse.status === 'success') {
                    setApiStatus(0);
                    message.success('歌曲创作完成，快去听听吧~');

                    // 获取专辑列表  
                    getAlbumList();
                } else {
                    console.error('add song to album failed');
                }
            } else {
                console.error('save song failed');
            }
        } catch (error) {
            console.error('save song error:', error);
        }
    };
    const pollForSongUrl = async (songId: number) => {
        const intervalId = setInterval(async () => {
            try {
                const res: any = await api.getSongById(songId);
                if (vendor === "TIANGONG" && res.msg === "OK") {
                    if (res.data.state === 3) {
                        clearInterval(intervalId);
                        const backUpToCloudResponse: any = await api.backUpToCloud({
                            src_url: res.data.songs[0].mp3_url,
                            dest_fname: `${songTitle}.mp3`
                        });
                        if (backUpToCloudResponse.status === "success") {
                            saveSong(songTitle, songLyric.replace(/\[.*?\]/g, ' '), backUpToCloudResponse.data.status, res.data.songs[0].cover_url, res.data.songs[0].genres.join(','));
                        } else {
                            console.error('song url request error');
                            setApiStatus(0);
                            message.error('生成歌曲失败，请重试~');
                        }
                    } else if (res.data.state === 4) {
                        clearInterval(intervalId);
                        setApiStatus(0);
                        message.error('生成歌曲失败，请重试~');
                    }
                } else if (vendor === "SUNO" && res && res[0]?.status === 'complete') {
                    clearInterval(intervalId);
                    if (res && res[0]?.status === 'complete') {
                        clearInterval(intervalId);
                        saveSong(res[0].title, res[0].lyric.replace(/\[.*?\]/g, ' '), res[0].audio_url, res[0].image_url, res[0].tags);
                    } else {
                        console.error('song url request error');
                    }
                } else {
                    console.error('song url request error');
                }
            } catch (error) {
                setApiStatus(0);
                clearInterval(intervalId);
                console.error('getSongById:', error);
            }
        }, 3000); // 每3秒轮询一次  
    };

    const handleGenerate = async () => {
        if (songTitle.trim() === "") {
            message.warning('请输入歌名~');
            return;
        }
        if (songLyric.trim() === "") {
            message.warning('请输入歌词~');
            return;
        }
        if (songStyle.trim() === "") {
            message.warning('请输入曲风~');
            return;
        }
        if (apiStatus === 1) {
            message.warning('正在生成中，请稍等');
            return;
        }
        setApiStatus(1);

        const requestData = {
            request_id: "string",
            title: songTitle,
            lyrics: songLyric,
            genre: songStyle,
        };

        try {
            const res: any = await musicGenCustom(requestData);

            if (vendor === "TIANGONG" && res.msg === "OK" && res.data.feed_id) {
                pollForSongUrl(res.data.feed_id);
            } else if (vendor === "SUNO" && res && res[0]?.id) {
                console.log('song id:', res[0].id);
                pollForSongUrl(res[0].id);
            } else {
                handleError();
            }
        } catch (error) {
            console.error('genMusic:', error);
            handleError();
        }
    };

    const handleError = () => {
        message.error('生成歌曲失败，请重试~');
        setApiStatus(0);
    }

    const handleMagicGen = (prompt: string, setHandler: any) => {
        //需要做rewrite
        const requestParams = LLMCallRequestData.genLLMCallParams(
            prompt,
            userInfoStore.userInfoData.id,
            userInfoStore.userInfoData.name,
            false,
        )

        requestLLMCallV2(requestParams).then((res) => {
            if (res) {
                console.log('res.content :', res.content);
                setHandler(res.content);
            } else {
                console.error('res is null');
            }
        }).catch((error) => {
            console.error('Error:', error);
        });

    };
    return (
        <div className={styles.customGen}>
            <div className={styles.customHeader}>
                <div className={styles.boboMusicIcon}>
                    <img src={YinFuIcon} />
                </div>
                <div className={styles.headerTitle}>BoBo音乐</div>
            </div>
            <div className={styles.songTitle}>
                <input
                    className={styles.input}
                    value={songTitle}
                    onChange={(e) => setSongTitle(e.target.value)}
                    placeholder='请输入歌名'
                />
                <div className={styles.magicGenBtn}>
                    <MagicWand onMagicGenerate={() => {
                        handleMagicGen("我想写一首歌，帮我生成一个歌名,直接给出歌名，不要其他的内容,不要引号", setSongTitle);
                    }} />
                </div>
            </div>
            <div className={styles.songLyric}>
                <textarea
                    className={styles.textarea}
                    value={songLyric}
                    onChange={(e) => setSongLyric(e.target.value)}
                    placeholder='请输入歌词'
                />
                <div className={styles.magicGenBtn}>
                    <MagicWand onMagicGenerate={() => {
                        if (songTitle.trim() === "") {
                            message.warning('请先生成歌名~');
                            return;
                        }
                        handleMagicGen(`我想写一首歌${songTitle}，帮我生成歌词，只要歌词内容，不要和歌词不相关的其他内容(包括歌名，小节提示等)`, setSongLyric);
                    }} />
                </div>
            </div>
            <div className={styles.songStyle}>
                <input
                    className={styles.input}
                    value={songStyle}
                    onChange={(e) => setSongStyle(e.target.value)}
                    placeholder='请输入曲风'
                />
                <div className={styles.magicGenBtn}>
                    <MagicWand onMagicGenerate={() => {
                        if (songTitle.trim() === "") {
                            message.warning('请先生成歌名~');
                            return;
                        }
                        handleMagicGen(`我想写一首歌${songTitle}，适合什么曲风，直接给出三到四个关键词,不要其他内容`, setSongStyle);
                    }} />
                </div>
            </div>

            <Button
                className={styles.genMusicButton}
                onClick={handleGenerate}
                loading={apiStatus === 1}
                icon={<img src={YinFuIcon} />}
            >
                {["开始创作", "创作中（大概需要2~3分钟）"][apiStatus]}
            </Button>
        </div>
    )
}

export default GenCustomMusic;