import { useEffect, useState } from 'react';
import styles from './course.module.less';
import { useNavigate } from 'react-router-dom';
import service from '@/services/axios';
import { Button, Modal, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { getNewCourseParams } from '@/api/course'
import VisibleToTeacher from '@/components/VisibleToTeacher/VisibleToTeacher';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import ChangeViewLayout from '@/pages/Layouts/ChangeViewLayout/ChangeViewLayout';
import UploadMediaBulk from '@/components/UploadMedia/UploadMediaBulk';
import CreateCourseModal from '@/components/CreateCourseModal/CreateCourseModal';
import VisibleToStudent from '@/components/VisibleToStudent/VisibleToStudent';
import { cloneDeep } from 'lodash';
import ClearRecordModal from '@/components/ClearRecordModal/ClearRecordModal';
import mixpanel from 'mixpanel-browser';
import { CourseData } from '@/base/CourseData';
import ProgressSegmentData from '@/base/ProgressSegmentData';
import KeyPointIcon from '@/assets/img/key_point_icon.svg';

const Course: React.FC = observer(() => {
	let navigate = useNavigate();
	const [courseList, setCourseList] = useState<CourseData[]>([]);
	const [inputCourseName, setInputCourseName] = useState('');//创建输入的课程名字
	const [inputCourseIntroduce, setInputCourseIntroduce] = useState('');//创建输入的课程介绍
	const [courseCoverUrl, setCourseCoverUrl] = useState('');//课程封面url
	const [isCreating, setIsCreating] = useState(false); // 用于追踪课程创建的加载状态
	const [modalType, setModalType] = useState('create'); // 'create' 或 'edit' 
	const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
	const [currentCourse, setCurrentCourse] = useState<CourseData | null>(null);// 当前正在编辑的课程
	const [keyPoints, setKeyPoints] = useState<string[]>([]); //课程关键点, 最多3个
	const [partSegments, setPartSegments] = useState<ProgressSegmentData[]>([]);//课程分段信息
	const [visibleToNormalUsers, setVisibleToNormalUsers] = useState(true);//是否对普通用户可见
	const [courseBGM, setCourseBGM] = useState<string>('');	//课程背景音乐
	const { confirm } = Modal;
	const { userInfoStore, lectureStore } = useStores();

	//Modal
	const [isModalOpen, setIsModalOpen] = useState(false);


	//useEffect(() => {
	// 这里可以进行一些操作，比如从服务器获取数据并更新 partSegments
	// setPartSegments(newData);
	// 最好目前不要删除这个console log
	//  	console.log("partSegments in Course useEffect", partSegments);
	//}, [partSegments]);

	const showModal = (type: string, course: CourseData | null) => {

		if (course) {
			if (course.extras?.partSegments !== undefined && course.extras?.partSegments.length > 0) {
				let partSegments = [];
				partSegments = course.extras?.partSegments.map((segment: ProgressSegmentData) => {
					return new ProgressSegmentData({ ...segment });
				});
				setPartSegments(partSegments);
			} else {
				setPartSegments(course.extras.partSegments);
			}
			if (course.extras?.visibleToNormalUsers === undefined) {
				course.extras.visibleToNormalUsers = true;
			}
			setKeyPoints(course.extras.keyPoints || []);
			setVisibleToNormalUsers(course.extras.visibleToNormalUsers);
			setCourseBGM(course.extras.bgm || '');

		}
		setCurrentCourse(course);
		setModalType(type);
		if (type === 'edit') {
			setInputCourseName(course!.name);
			setInputCourseIntroduce(course!.extras ? course!.extras.introduce : '');
			setCourseCoverUrl(course!.extras ? course!.extras.cover : '');
		} else if (type === 'create') {
			setInputCourseName('');
			setInputCourseIntroduce('');
			setCourseCoverUrl('');
			setPartSegments([]);
			setVisibleToNormalUsers(true);
		}
		setIsModalOpen(true);
	};

	const handleOk = async (segments: any) => {
		if (modalType === 'create') {
			await createCourse();
		} else if (modalType === 'edit') {
			await updateCourse();
		}
		setIsModalOpen(false);
		setInputCourseName('');
		setInputCourseIntroduce('');
		setCourseCoverUrl('');
		setPartSegments(segments);
		setVisibleToNormalUsers(true);
	};


	const handleCancel = () => {
		setIsModalOpen(false);
		setInputCourseName('');
		setInputCourseIntroduce('');
		setCourseCoverUrl('');
		setPartSegments([]);
		setVisibleToNormalUsers(true);
	};

	const handleUploadModalOk = () => {
		setIsUploadModalVisible(false);
	};

	const handleUploadModalCancel = () => {
		setIsUploadModalVisible(false);
	};


	const onUploadSuccess = (file_url: string) => {
		console.log(`${file_url} uploaded successfully`);
		/*if (info.file.status === 'done') {
		message.success(`${info.file.name} file uploaded successfully`);
		console.log(`${info.file.name} file uploaded successfully`);
		} else if (info.file.status === 'error') {
		message.error(`${info.file.name} file upload failed.`);
		console.log(`${info.file.name} file upload failed.`);
		}*/
	};

	const goLessonSection = async (course: CourseData) => {
		const courseId = course.id;
		const chapterId = course.chapters[0]?.id;
		const sectionId = course.chapters[0]?.sections[0]?.id;
		// 需要setPartSegments
		//ReactGA.event({
		//  category: 'zuoning',
		//  action: 'Clicked goLessonSection'
		//});
		if (import.meta.env.PROD) {
			mixpanel.track('goLessonSection', {
				'courseId': courseId,
				'courseName': course.name,
				'sectionId': sectionId,
			});
		}

		console.log("goLessonSection", course);

		const partSegments: ProgressSegmentData[] = [];
		if (course.extras.partSegments) {
			for (let i = 0; i < course.extras.partSegments.length; i++) {
				partSegments.push(new ProgressSegmentData({
					endPos: course.extras.partSegments[i].endPos,
					desc: course.extras.partSegments[i].desc
				}));
			}
		}

		lectureStore.setPartSegments(partSegments);
		if (course.extras.bgm) {
			lectureStore.setCourseBGM(course.extras.bgm);
		} else {
			lectureStore.setCourseBGM('');
		}
		navigate(`/coursestudy/${courseId}/${chapterId}/${sectionId}`);
	};
	//获取输入框内容
	const courseNameChange = (e: any) => {
		setInputCourseName(e.target.value); // 使用事件对象e中的value属性来更新状态
	};

	const courseIntroduceChange = (e: any) => {
		setInputCourseIntroduce(e.target.value);
	};

	const courseKeyPointChange = (index: number, value: string) => {
		const newKeyPoints = cloneDeep(keyPoints);
		newKeyPoints[index] = value;
		setKeyPoints(newKeyPoints);
	};

	const courseCoverUrlChange = (url: string) => {
		setCourseCoverUrl(url);
	};

	const coursePartSegmentsChange = (index: number, key: string, value: any) => {
		console.log("coursePartSegmentsChange", index, key, value);
		let segments: ProgressSegmentData[] = cloneDeep(partSegments);
		if (key === 'new') {
			// 这里好像有些异步的问题
			if (segments === undefined) {
				segments = [];
			}
			segments.push(new ProgressSegmentData({ endPos: 0, desc: '' }));
		} else if (key === 'del') {
			segments.splice(index, 1);
		} else {
			segments[index].setField((key as keyof ProgressSegmentData), value);
		}
		setPartSegments(segments);
	};

	const courseVisibleToNormalUsersChange = (value: boolean) => {
		setVisibleToNormalUsers(value);
	}

	const courseBGMChange = (url: string) => {
		setCourseBGM(url);
	}

	// 获取课程列表
	const getCourseList = async () => {
		try {
			const response: any = await service.get('/course_all/', { params: { mode: 1 } });//0不详细内容 1全部内容
			if (response.status === 'success') {
				setCourseList(response.data);
			} else {
				message.error('请求出错')
				setCourseList([]);
			}
		} catch (error) {
			console.error("Failed to fetch course list:", error);
		}
	}

	// 创建课程
	const createCourse = async () => {
		if (inputCourseName.trim() === '' || inputCourseIntroduce.trim() === '' || courseCoverUrl.trim() === '') {
			message.error("请填写完整信息");
			return;
		}
		const extras = {
			cover: courseCoverUrl,
			introduce: inputCourseIntroduce,
			partSegments: partSegments.map(segment => ({
				endPos: segment.endPos,
				lengthPercent: segment.lengthPercent,
				progress: segment.progress,
				desc: segment.desc
			})),
			visibleToNormalUsers: visibleToNormalUsers,
			keyPoints: keyPoints,
			bgm: courseBGM
		};
		const courseParams = getNewCourseParams(inputCourseName, extras);
		setIsCreating(true); // 开始创建，设置isLoading为true
		try {
			const response: any = await service.post('/course_express', courseParams);
			if (response.status === "success") {
				// 课程创建成功，更新课程列表
				await getCourseList();
			} else {
				console.log("Course creation was not successful");
				// 处理未成功创建的情况
			}
		} catch (error) {
			console.error("Failed to create course:", error);
			// 处理发生的错误
		} finally {
			setIsCreating(false); // 请求完成，无论成功或失败，关闭 loading 状态
		}
	};
	//修改课程名
	const updateCourse = async () => {

		if (currentCourse === null || (inputCourseName.trim() === '' && inputCourseIntroduce.trim() === '' && courseCoverUrl.trim() === '')) {
			return; // 确保有课程ID和非空的课程名
		}
		setIsCreating(true); // 开始请求前显示加载状态
		try {
			//目前的课程只有一个section，把它找出来s
			const updatedCourse = {
				...currentCourse,
				name: inputCourseName,
				extras: {
					cover: courseCoverUrl,
					introduce: inputCourseIntroduce,
					// 这里存的就是CamelCase的字段名
					partSegments: partSegments.map(segment => ({
						endPos: segment.endPos,
						lengthPercent: segment.lengthPercent,
						progress: segment.progress,
						desc: segment.desc
					})),
					visibleToNormalUsers: visibleToNormalUsers,
					keyPoints: keyPoints,
					bgm: courseBGM
				}
			};
			const response: any = await service.put('/course', updatedCourse);
			if (response.status === "success") {
				// 写入lectureStore
				lectureStore.setPartSegments(partSegments);
				// 课程修改成功，重新获取课程列表
				await getCourseList();
			} else {
				message.error("修改失败");
				console.error("Course update was not successful");
				// 处理未成功修改的情况
			}
		} catch (error) {
			console.error("Failed to update course:", error);
			// 处理发生的错误
		} finally {
			setIsCreating(false); // 关闭加载状态
			setIsModalOpen(false); // 关闭Modal
			setCurrentCourse(null); // 清空正在编辑的课程
		}
	};


	//删除课程
	const deleteCourse = async (courseId: number) => {
		confirm({
			title: '确认删除课程?',
			content: '删除后将无法找回这个课程，你确定要删除吗？',
			async onOk() {
				try {
					const response: any = await service.delete(`/course/${courseId}`);
					if (response.status === "success") {
						// 课程删除成功，重新获取课程列表
						await getCourseList();
					} else {
						message.error("删除失败");
						console.error("Course delete was not successful");
						// 处理未成功修改的情况
					}
				} catch (error) {
					console.error("Failed to delete course:", error);
					// 处理发生的错误
				}
			},
			centered: true,
			okText: '确认',
			cancelText: '取消'
		});
	}

	//复制课程
	const copyCourse = async (courseId: number) => {
		try {
			const response: any = await service.post(`/course_dup/${courseId}`);
			if (response.status === "success") {
				// 课程复制成功，重新获取课程列表
				await getCourseList();
			} else {
				message.error("复制失败");
				console.error("Course copy was not successful");
				// 处理未成功修改的情况
			}
		} catch (error) {
			console.error("Failed to copy course:", error);
			// 处理发生的错误
		}
	}

	const publishCourse = async (courseId: number) => {
		console.log("publishCourse", courseId);
		try {
			const response: any = await service.post(`/publish_course/${courseId}`);
			if (response.status === "success") {
				// 课程复制成功，重新获取课程列表
				//await getCourseList();
			} else {
				message.error("publish失败");
				console.error("Course publish was not successful");
				// 处理未成功修改的情况
			}
		} catch (error) {
			console.error("Failed to publish course:", error);
			// 处理发生的错误
		}

	}

	const shallShow = (course: CourseData, role: number) => {
		return course.extras.visibleToNormalUsers || (!course.extras.visibleToNormalUsers && role === 1);
	};

	useEffect(() => {
		getCourseList();
	}, []);

	return (
		<>
			<div className={styles.container}>
				{
					userInfoStore.userInfoData.userRole === 1 &&
					<div className={styles.headBar}>
						<ChangeViewLayout />
						<VisibleToTeacher>
							<div className={styles.createCourse}>
								<Button size='large' className={styles.createBtn} onClick={() => showModal('create', null)}>创建课程</Button>
							</div>
						</VisibleToTeacher>
						<VisibleToTeacher>
							<div className={styles.createCourse}>
								<Button size='large' className={styles.createBtn} onClick={() => setIsUploadModalVisible(true)}>上传文件</Button>
								<UploadMediaBulk isVisible={isUploadModalVisible}
									successCallBack={onUploadSuccess}
									handleOk={handleUploadModalOk}
									handleCancel={handleUploadModalCancel}
								/>
							</div>
						</VisibleToTeacher>
					</div>
				}
				<div className={styles.contentBox}>
					<div className={styles.title}>
						你好啊，欢迎来到萝卜AI
					</div>
					<div className={styles.introduce}>
						我是你的学习伙伴，在这里我们将一起掌握并了解AI的知识
					</div>
					<div className={styles.courseListBox}>
						{courseList?.map(item => (
							shallShow(item, userInfoStore.userInfoData.userRole) &&
							<div key={item.id} className={styles.courseCardBox}>
								<div className={styles.courseCard}>
									<div className={styles.courseImg} style={{ background: item?.extras?.cover ? '#ffffff' : "" }}>
										<img src={item?.extras?.cover} alt="" />
									</div>
									<div className={styles.content}>
										<div className={styles.topBox}>
											<div className={styles.courseName}>{item.name}</div>
											<div className={styles.courseIntroduce}>
												{item?.extras?.introduce}
											</div>
											<div className={styles.keyPoints}>
												<div className={styles.keyPointTitle}>
													<img src={KeyPointIcon} alt="" className={styles.keyPointIcon} />
													课程目标
												</div>
												<div className={styles.keyPointContent}>
													{item?.extras?.keyPoints?.map((keyPoint: string, index: number) => (
														<div key={index} className={styles.pointItem}>
															{keyPoint}
															<div className={styles.circleIcon}></div>
															<div className={styles.lineIcon}></div>
														</div>
													))}
												</div>
											</div>
										</div>

										<button className={styles.startLearnBtn} onClick={() => goLessonSection(item)}>
											<VisibleToStudent>开始学习</VisibleToStudent>
											<VisibleToTeacher>编辑课程</VisibleToTeacher>
										</button>
									</div>
									<VisibleToTeacher>
										<div className={styles.delBtn}
											onClick={(e) => {
												e.stopPropagation();
												deleteCourse(item.id)
											}}>
											{<DeleteOutlined />}
										</div>
									</VisibleToTeacher>
									<VisibleToStudent>
										<div className={styles.delBtn} onClick={
											(e) => {
												e.stopPropagation();
												e.preventDefault();
											}
										}>
											<ClearRecordModal
												userId={userInfoStore.userInfoData.id}
												sectionId={item.chapters[0]?.sections[0]?.id}
											/>
										</div>
									</VisibleToStudent>
								</div>
								<VisibleToTeacher>
									<div className={styles.updateBtn}>
										<Button onClick={() => showModal('edit', item)}>修改课程信息</Button>
										<Button onClick={() => copyCourse(item.id)}>复制课程</Button>
										<Button onClick={() => publishCourse(item.id)}>publish到{
											import.meta.env.PROD ? "Test环境" : "Prod环境"}</Button>
									</div>
								</VisibleToTeacher>
							</div>
						))}
						{/* 单数时候加一个空白的div */}
						{courseList.length % 2 === 1 && <div className={styles.courseCardBoxEmpty}></div>}
					</div>
				</div>
			</div>
			<CreateCourseModal
				isModalOpen={isModalOpen}
				handleOk={handleOk}
				handleCancel={handleCancel}
				inputCourseName={inputCourseName}
				inputCourseIntroduce={inputCourseIntroduce}
				courseCoverUrl={courseCoverUrl}
				courseKeyPoints={keyPoints}
				coursePartSegments={partSegments}
				courseVisibleToNormalUsers={visibleToNormalUsers}
				courseBGM={courseBGM}
				courseNameChange={courseNameChange}
				courseCoverUrlChange={courseCoverUrlChange}
				courseIntroduceChange={courseIntroduceChange}
				courseKeyPointChange={courseKeyPointChange}
				coursePartSegmentsChange={coursePartSegmentsChange}
				courseVisibleToNormalUsersChange={courseVisibleToNormalUsersChange}
				courseBGMChange={courseBGMChange}
				totalBlocks={currentCourse?.chapters[0]?.sections[0]?.blks.length || 0}
				isCreating={isCreating}
			/>
		</>
	);
});

export default Course;