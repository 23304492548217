import { useState } from 'react';
import { message, Modal, Input, InputNumber, Radio } from 'antd';
import UserManagementAPI from '@/api/userManagement';
import UserPng from '@/assets/img/user.png';
import UploadMedia from '@/components/UploadMedia/UploadMedia';
import styles from './userInfoModifierModal.module.less';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import UserInfoData from '@/base/UserInfoData';

interface UserInfoModifierModalProps {
    isModifierModalOpen: boolean;
    setIsModifierModalOpen: (isModifierModalOpen: boolean) => void;
};

const UserInfoModifierModal: React.FC<UserInfoModifierModalProps> = observer(({ 
    isModifierModalOpen, 
    setIsModifierModalOpen}) => {
    const userAPI = new UserManagementAPI();
    const { userInfoStore } = useStores();
    const [userName, setUserName] = useState(userInfoStore.userInfoData.name);
    const [userAge, setUserAge] = useState(userInfoStore.userInfoData.age || 10);
    const [userTagline, setUserTagline] = useState(userInfoStore.userInfoData.tagLine || '');
    const [userAvatar, setUserAvatar] = useState(userInfoStore.userInfoData.avatarUrl || UserPng);
    const [userGender, setUserGender] = useState(userInfoStore.userInfoData.gender || 'male');

    const closeModal = () => {
        setIsModifierModalOpen(false);
    }
    
    const saveUserInfo = async () => {
        const data =  new UserInfoData({
            id: userInfoStore.userInfoData.id,
            name: userName,
            avatarUrl: userAvatar,
            age: userAge,
            tagLine: userTagline,
            gender: userGender
            
        });
        //用户名不能为空，不能超过10个字
        const trimmedName = userName.trim();
        if (trimmedName === '') {
            message.error('用户名不能为空');
            return;
        }
        if (trimmedName.length > 20) {
            message.error('用户名不能超过20个字');
            return;
        }
        try {
            // 这里应该使用incremental update
            const response = await userAPI.updateUserIncremental(data);
            if (response.status === 'success') {
                userInfoStore.updateUserInfoFromData(response.data);
                closeModal();
            }
        } catch (error) {
            console.error('Error saving user info');
            message.error("修改信息失败");
        }
    }

    return (
        <>
            <Modal
                open={isModifierModalOpen}
                title="修改个人信息"
                onOk={saveUserInfo}
                okText="保存"
                cancelText="取消"
                onCancel={closeModal}
                centered
                className={styles.modal}
            >
                <div className={styles.modifierBox}>
                    <div className={styles.avatarModifier}>
                        <img src={userAvatar} alt="userAvatar" className={styles.avatarImg} />
                        <div className={styles.uploadAvatar}>
                            <UploadMedia
                                successCallBack={(url) => {
                                    setUserAvatar(url);
                                }}
                                btn_text="上传头像"
                                accept_str="image/*"
                                dir="avatar"
                                isMultiple={false}
                            />
                        </div>
                    </div>
                    <div className={styles.inputContainer}>
                        <div className={styles.inputLabel}>用户名:</div>
                        <Input
                            className={styles.inputField}
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                            placeholder="请输入用户名"
                        />

                        <div className={styles.inputLabel}>年龄:</div>
                        <InputNumber
                            className={styles.inputField}
                            value={userAge}
                            onChange={(value: number | null) => setUserAge(value!)}
                            placeholder="请输入年龄"
                        />

                        <div className={styles.inputLabel}>性别:</div>
                        <Radio.Group
                            className={styles.inputField}
                            value={userGender}
                            onChange={(e) => setUserGender(e.target.value)}
                        >
                            <Radio value={'male'}>男</Radio>
                            <Radio value={'female'}>女</Radio>
                        </Radio.Group>

                        <div className={styles.inputLabel}>签名档:</div>
                        <Input
                            className={styles.inputField}
                            value={userTagline}
                            onChange={(e) => setUserTagline(e.target.value)}
                            placeholder="请输入签名档"
                        />
                    </div>
                </div>
            </Modal>
        </>
    )
})

export default UserInfoModifierModal;
