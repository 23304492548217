import DataDefinition from "./DataDefinition";

class SongData extends DataDefinition{
    id: number;
    name: string;
    lyrics: string;
    musicUrl: string;
    coverUrl: string;

    constructor({
        id = 0,
        name = "",
        lyrics = "",
        musicUrl = "",
        coverUrl = ""
        }: Partial<SongData> = {}) {
        super();
        this.allowedFields = ['id', 'name', 'lyrics', 'musicUrl', 'coverUrl'];
        this.id = id;
        this.name = name;
        this.lyrics = lyrics;
        this.musicUrl = musicUrl;
        this.coverUrl = coverUrl;
    }
};

export default SongData;
