import styles from './musicControl.module.less';
import PropTypes from 'prop-types';
import PreviousSongIcon from '@/assets/img/playground/previous_song.svg';
import NextSongIcon from '@/assets/img/playground/next_song.svg';
import PlayIcon from '@/assets/img/playground/play_music.svg';
import PauseIcon from '@/assets/img/playground/pause_music.svg';

interface MusicControlProps {
    isPlaying: boolean;
    playMusic: () => void;
    pauseMusic: () => void;
    handlePreSong: () => void;
    handleNextSong: () => void;
}

const MusicControl: React.FC<MusicControlProps> = ({ 
    isPlaying, playMusic, pauseMusic,
    handlePreSong, handleNextSong}) => {
    const handlePlay = () => {
        if (isPlaying) {
            pauseMusic();
        } else {
            playMusic();
        }
    }
    return (
        <div className={styles.musicControl}>
            <img src={PreviousSongIcon} className={styles.previousBtn} onClick={handlePreSong} />
            <img src={isPlaying ? PauseIcon : PlayIcon} className={styles.playIcon} onClick={handlePlay} />
            <img src={NextSongIcon} className={styles.nextBtn} onClick={handleNextSong} />
        </div>
    )
}

export default MusicControl;