import styles from "./playGround.module.less";
import playGroundList from './playgroundList';
import { useNavigate } from 'react-router-dom';
import mixpanel from "mixpanel-browser";

const PlayGround: React.FC = () => {
    const navigate = useNavigate();

    const playGroundItemClick = (path: string) => {
        if(import.meta.env.PROD) {
            mixpanel.track("playground_click", { path });
        }
        navigate(path);
    }
    return (
        <>
            <div className={styles.container}>
                <div className={styles.contentBox}>
                    <div className={styles.title}>
                        欢迎来到游乐场
                    </div>
                    <div className={styles.introduce}>
                        我是你的学习伙伴，在这里我们将一起掌握并了解AI的知识
                    </div>
                    <div className={styles.playGroundListBox}>
                        {playGroundList?.map(item => (
                            <div
                                key={item.id}
                                className={styles.playGroundItem}
                                onClick={() => playGroundItemClick(item.path)}
                            >
                                <div
                                    className={styles.cover}
                                    style={{ backgroundColor: item.coverImg ? "#fff" : "#73A2FF" }}
                                >
                                    {item.coverImg && <img src={item.coverImg} alt="" />}
                                </div>
                                <div className={styles.playGroundItemName}>{item.name}</div>
                                <div className={styles.playGroundItemContent}>{item.introduce}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default PlayGround;