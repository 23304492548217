import { useState } from 'react';
import styles from './shareCode.module.less';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrowNight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import CodeGreyIcon from '@/assets/img/code_grey_icon.svg';
import CodeBlueIcon from '@/assets/img/code_blue_icon.svg';
import GameBlueIcon from '@/assets/img/game_blue_icon.svg';
import GameGreyIcon from '@/assets/img/game_grey_icon.svg';
import DownloadIcon from '@/assets/img/download_icon.svg';
import { useMediaQuery } from 'react-responsive';
import CodeInfo from '@/base/ShareData/CodeInfo';

interface ShareCodeProps {
    codeInfo: CodeInfo;
}

const ShareCode: React.FC<ShareCodeProps> = ({ codeInfo }) => {
    const [showMode, setShowMode] = useState("iframe");
    // 使用 useMediaQuery 钩子来检测屏幕宽度  
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isNotMobile = useMediaQuery({ query: '(min-width: 769px)' });

    const handleDownload = () => {
        const codeContent = codeInfo.srcCode;
        const blob = new Blob([codeContent], { type: 'text/html' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'code.html'; // 设置文件名  
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    return (
        <div className={styles.page}>
            {isNotMobile && (
                <div className={styles.codeRegion} >
                    <div className={styles.codeContainer}>
                        {showMode === "iframe" &&
                            <iframe className={styles.iframe}
                                srcDoc={codeInfo.srcCode}
                            />
                        }
                        {showMode === "code" &&
                            <div className={styles.code}>
                                <SyntaxHighlighter language="html" style={tomorrowNight}>
                                    {codeInfo.srcCode}
                                </SyntaxHighlighter>
                            </div>
                        }
                    </div>
                    <div className={styles.operationArea}>
                        <div className={styles.topBtnBox}>
                            <div
                                className={`${showMode === "iframe" ? styles.active : ''} ${styles.normalBtn}`}
                                onClick={() => setShowMode('iframe')}>
                                <img
                                    src={showMode === "iframe" ? GameBlueIcon : GameGreyIcon} className={styles.btnIcon} />
                                <span className={styles.btnText}>游戏</span>
                            </div>
                            <div
                                className={`${showMode === "code" ? styles.active : ''} ${styles.normalBtn}`}
                                onClick={() => setShowMode('code')}>
                                <img
                                    src={showMode === "code" ? CodeBlueIcon : CodeGreyIcon} className={styles.btnIcon} />
                                <span className={styles.btnText}>代码</span>
                            </div>
                        </div>
                        <div className={styles.bottomBtnBox}>
                            <div className={styles.normalBtn} onClick={handleDownload}>
                                <img src={DownloadIcon} className={styles.btnIcon} />
                                <span className={styles.btnText}>下载</span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {
                isMobile && (
                    <div className={styles.mobileCodeLayout}>
                        <div className={styles.mobileCodeContainer}>
                            {showMode === "iframe" &&
                                <iframe className={styles.mobileIframe}
                                    srcDoc={codeInfo.srcCode}
                                />
                            }
                            {showMode === "code" &&
                                <div className={styles.mobileCode}>
                                    <SyntaxHighlighter language="html" style={tomorrowNight}>
                                        {codeInfo.srcCode}
                                    </SyntaxHighlighter>
                                </div>
                            }
                        </div>
                        <div className={styles.mobileOperationArea}>
                            <div
                                className={`${showMode === "iframe" ? styles.mobileActive : ''} ${styles.mobileNormalBtn}`}
                                onClick={() => setShowMode('iframe')}>
                                <img
                                    src={showMode === "iframe" ? GameBlueIcon : GameGreyIcon} className={styles.btnIcon} />
                            </div>
                            <div
                                className={`${showMode === "code" ? styles.mobileActive : ''} ${styles.mobileNormalBtn}`}
                                onClick={() => setShowMode('code')}>
                                <img
                                    src={showMode === "code" ? CodeBlueIcon : CodeGreyIcon} className={styles.btnIcon} />
                            </div>
                            <div className={styles.mobileNormalBtn} onClick={handleDownload}>
                                <img src={DownloadIcon} className={styles.btnIcon} />
                            </div>
                        </div>
                    </div>
                )}
        </div>
    )
}

export default ShareCode;