import { useState, useEffect, useRef } from 'react';
import styles from './chatRegion.module.less';
import HiLogo from '@/assets/img/chat_region_hi.svg';
import SpeechToText from '@/components/SpeechToText/SpeechToText';
import SendDisableIcon from '@/assets/img/send_disable.svg';
import SendAbleIcon from '@/assets/img/send_able.svg';
import { cloneDeep } from "lodash";
import {requestLLMCall, requestLLMCallV2} from '@/services/requestLLMCall';
import { parseContent } from "@/utils/utils";
import service from '@/services/axios';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import { ChatMessage, MediaInfo } from '@/base/ChatMessage';
import LLMCallRequestData from '@/base/LLMCallRequestData';
import ChatBubble from '@/components/Element/AIChatRegion/ChatBubble';


const ChatRegion: React.FC = observer(() => {
    const { userInfoStore, ttsStore } = useStores();
    const [chatList, setChatList] = useState<ChatMessage[]>([]);
    const [inputValue, setInputValue] = useState('');
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const chatContainerRef = useRef<HTMLDivElement>(null);
    const isComponentMounted = useRef<boolean>(false);
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const endMarkArr = ['.', '?', '!', ',', '，', '。', '？', '！']; // 定义句子结束标记

    const sendStreamCall = async (requestParams: LLMCallRequestData, messages: ChatMessage[]) => {
        console.log('LLM 向后端发送POST请求:', requestParams.toJSON());
        const response: any = await requestLLMCall(requestParams);
        if (!response.ok) {
            console.log('请求失败，状态码为：' + response.status);
            // 这里可以返回或抛出错误
            return;
        }
        console.log('LLM 请求成功');
        const reader = response.body.getReader();
        //转为音频的文字片段
        let responseTextBuffer = '';
        try {
            while (true) {
                const { done, value } = await reader.read();

                if (done) {
                    // 在最后结束的时候可能还有剩余的字符串没有处理，也需要进行处理
                    if (responseTextBuffer.length > 0 && isComponentMounted.current) {
                        // await textToSpeech(responseTextBuffer);

                    }
                    responseTextBuffer = ""; // 清空
                    break;
                }
                let str = new TextDecoder("utf-8").decode(value);
                let parseStr = parseContent(str);
                messages[messages.length - 1].rawContent += parseStr;
                responseTextBuffer += parseStr;
                const newChatList = cloneDeep(messages);
                setChatList(newChatList);
                //转语音
                // while (hasEnoughCharacters(responseTextBuffer)) {
                //     let endMarkIndex = responseTextBuffer.length;
                //     for (let i = 0; i < endMarkArr.length; i++) {
                //         let index = responseTextBuffer.indexOf(endMarkArr[i]);
                //         if (index !== -1 && index < endMarkIndex) {
                //             endMarkIndex = index;
                //         }
                //     }
                //     let group = responseTextBuffer.slice(0, endMarkIndex + 1);
                //     responseTextBuffer = responseTextBuffer.slice(endMarkIndex + 1);
                //     //console.log("textToSpeech", group);
                //     if (isComponentMounted.current) {
                //         await textToSpeech(group);
                //     }
                // }
            }
        } catch (error) {
            console.error('读取流数据时出错：', error);
        } finally {
            setIsFetching(false);
        }

    }

    const sendNonStreamCall = async (requestParams: LLMCallRequestData, 
                                    messages: ChatMessage[],
                                    mode: number = 1) => {
        console.log('sendNonStreamCall - msgs:', messages);
        const res = await requestLLMCallV2(requestParams, mode);
        console.log('res in sendNonStreamCall:', res); 
        const newMessages = cloneDeep(messages);
        // mode 1的response格式不一样
        if(mode === 1) {
            if (res.type === 'image') {
                const imgMessage = new ChatMessage(
                    { type: 4, 
                    mediaInfo: new MediaInfo({mediaType: "image", mediaUrl: res.file_url}),
                    rawContent: res.description }); 
                newMessages[messages.length - 1] = imgMessage;
            } else if (res.type === 'text') {             
                newMessages[messages.length - 1].rawContent = res.content;               
            } else if (res.type === 'video') {
                const videoMessage = new ChatMessage(
                    { type: 5, 
                    mediaInfo: new MediaInfo({mediaType: "video", mediaUrl: res.file_url}),
                    rawContent: res.description }); 
                newMessages[messages.length - 1] = videoMessage;
            }
        } 

        setChatList(newMessages);     
        setIsFetching(false);
    }

    //发送消息
    const handleSend = async () => {
        let userInput = inputValue;
        console.log('handleSend in AIChat - userInput:', userInput);
        if (isFetching || userInput.trim() === "") return;
        const userMessage = new ChatMessage({ type: 3, rawContent: userInput });
        // gpt response可能不是raw message，有可能返回图像
        let gptMessage = new ChatMessage({ type: 1, rawContent: "" });
        const newMessages = [...chatList, userMessage, gptMessage];
        setChatList(newMessages);
        ttsStore.clearAudioSegments();
        setInputValue("");
        setIsFetching(true);  // 开始获取数据

        const stream: boolean = false;
        const systemPrompt: string = '';
        const tools: string = 'draw,video_generation';

        const requestParams = LLMCallRequestData.genLLMCallParams(
            userInput,
            userInfoStore.userInfoData.id, 
            userInfoStore.userInfoData.name,
            stream, 
            chatList, 
            systemPrompt, 
            tools);
        
        if(stream){
            sendStreamCall(requestParams, newMessages);
        } else {
            sendNonStreamCall(requestParams, newMessages);
        }
        
    };


    const hasEnoughCharacters = (responseTextBuffer: string) => {
        // return responseTextBuffer.length >= 10 ? true : false;
        return endMarkArr.some((endMark) => responseTextBuffer.includes(endMark)); // 判断是否有句子结束
    }

    const textToSpeech = async (text: string) => {
        try {
            const res: any = await service.post('/tts', {
                text: text,
            });
            if (res.status === 'success' && res.data !== null) {
                const audioSegment = res.data;
                if (isComponentMounted.current) {
                    ttsStore.addAudioSegment(audioSegment);
                }
            }
        } catch (error) {
            console.log('error:', error);
        }
    }
    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSend();
        }
    };
    // 添加useEffect以处理自动滚动
    useEffect(() => {
        if (chatContainerRef.current) {
            const { current: container } = chatContainerRef;
            // 使用scrollTo方法进行平滑滚动
            container.scrollTo({
                top: container.scrollHeight,
                behavior: 'smooth' // 这个设置能使滚动行为变得平滑
            });
        }
    }, [chatList]); // 依赖于chat_list的变化
    useEffect(() => {
        const playAudio = () => {
            if (ttsStore.audioSegments.length > 0) {
                audioRef.current = new Audio(`data:audio/mp3;base64,${ttsStore.audioSegments[0]}`);
                audioRef.current.play();
                ttsStore.setPlaying(true);
                audioRef.current.onended = () => {
                    ttsStore.removeAudioSegment();
                    ttsStore.setPlaying(false);
                };
            }
        };

        if (!ttsStore.isPlaying && ttsStore.audioSegments.length > 0) {
            playAudio(); // only start a new audio when there is no audio currently playing
        }
    }, [ttsStore.audioSegments.length, ttsStore.isPlaying]);
    useEffect(() => {
        isComponentMounted.current = true;
        return () => {
            isComponentMounted.current = false;
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current = null;
            }
            ttsStore.resetTTSStore();
        }
    }, [])
    return (
        <div className={styles.chatBox}>
            <div className={styles.chatHead}>
                <img src={HiLogo} className={styles.chatLogo} />
                <div className={styles.chatTitle}>ChatGPT对话</div>
            </div>
            <div
                className={styles.aiChat}
                ref={chatContainerRef}
            >
                {chatList.map((item, index) => {
                    return (
                        <ChatBubble key={index} item={item} role="gpt" />
                    )
                }
                )}
            </div>
            <div className={styles.inputAreaBox}>
                <div className={`${isInputFocused ? styles.inputAreaBoxBorder : ''} ${styles.box}`}>
                    <div className={styles.audioBtn}>
                        <SpeechToText
                            setInputMessage={setInputValue}
                            inputMessage={inputValue}
                        />
                    </div>
                    <div className={styles.inputBox}>
                        <textarea
                            className={styles.inputArea}
                            placeholder="输入消息..."
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            onKeyDown={handleKeyDown}
                            onFocus={() => setIsInputFocused(true)}
                            onBlur={() => setIsInputFocused(false)}
                        />
                    </div >
                    <div className={styles.sendBtn}>
                        <img
                            src={inputValue.trim() === '' ? SendDisableIcon : SendAbleIcon}
                            onClick={(e) => {
                                e.preventDefault();
                                handleSend();
                            }}
                        />
                    </div>
                </div>
            </div>

        </div>
    )
})

export default ChatRegion;
