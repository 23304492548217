import React from 'react';
import styles from './AIChatWS.module.less';
import ChatRegionWS from './ChatRegionWS/ChatRegionWS';
import { useNavigate } from 'react-router-dom';

const AIChat: React.FC = () => {
    const navigate = useNavigate();
    return (
        <div className={styles.container}>
            <div className={styles.exitBtnBox}>
                <button
                    className={styles.exitBtn}
                    onClick={() => navigate('/playground')}
                >
                    退出Chat
                </button>
            </div>
            <ChatRegionWS />
        </div>
    )
}

export default AIChat;
