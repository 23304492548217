import { useRef, useState, useEffect } from 'react';  
import styles from './musicProgress.module.less';  

interface MusicProgressProps {
    progress: number;
    handleProgressChange: (newProgress: number) => void;
    formattedCurrentTime: string;
    formattedDuration: string;
};

const MusicProgress: React.FC<MusicProgressProps> = ({ progress, handleProgressChange, formattedCurrentTime, formattedDuration }) => {  
    const [isProgressDragging, setIsProgressDragging] = useState(false);  
    const sliderRef = useRef<HTMLDivElement>(null);  

    const progressDown = (e: any) => {  
        e.preventDefault();  
        setIsProgressDragging(true);  
    };  

    const handleUp = () => {  
        setIsProgressDragging(false);  
    };  

    const handleProgressClick = (e: any) => {  
        if (!sliderRef.current) return;
        const boundingRect = sliderRef.current.getBoundingClientRect();  
        const offsetX = (e.touches ? e.touches[0].clientX : e.clientX) - boundingRect.left;  
        const width = boundingRect.width;  
        const newProgress = Math.min(100, Math.max(0, Math.round(offsetX / width * 100))); // Ensure newProgress is between 0 and 100  
        handleProgressChange(newProgress);  
    };  

    const progressMove = (e: any) => {  
        if (isProgressDragging) {  
            const boundingRect = sliderRef.current!.getBoundingClientRect();  
            const offsetX = (e.touches ? e.touches[0].clientX : e.clientX) - boundingRect.left;  
            const width = boundingRect.width;  
            const newProgress = Math.min(100, Math.max(0, Math.round(offsetX / width * 100))); // Ensure newProgress is between 0 and 100  
            handleProgressChange(newProgress);  
        }  
    };  

    useEffect(() => {  
        document.addEventListener('mousemove', progressMove);  
        document.addEventListener('mouseup', handleUp);  
        document.addEventListener('touchmove', progressMove);  
        document.addEventListener('touchend', handleUp);  
        return () => {  
            document.removeEventListener('mousemove', progressMove);  
            document.removeEventListener('mouseup', handleUp);  
            document.removeEventListener('touchmove', progressMove);  
            document.removeEventListener('touchend', handleUp);  
        };  
    }, [isProgressDragging]);  

    return (  
        <div className={styles.progressBox}>  
            <div className={styles.currentTime}>  
                {formattedCurrentTime}  
            </div>  
            <div className={styles.progress} onClick={handleProgressClick} ref={sliderRef}  
                onMouseDown={progressDown} onTouchStart={progressDown}>  
                <div className={styles.progressBar} style={{ width: `${progress}%` }}>  
                    <div className={styles.progressDot}></div>  
                </div>  
            </div>  
            <div className={styles.durationTime}>  
                {formattedDuration}  
            </div>  
        </div>  
    )  
}  

export default MusicProgress;