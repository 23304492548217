import { useRef, useState, useEffect } from 'react';
import styles from './musicVolume.module.less';
import VolumeControlIcon from '@/assets/img/playground/volume_control.svg';
import PropTypes from 'prop-types';

interface MusicVolumeProps {
    volume: number;
    handleVolumeChange: (newVolume: number) => void;
};

const MusicVolume: React.FC<MusicVolumeProps> = ({ volume, handleVolumeChange }) => {
    const [isVolumeDragging, setIsVolumeDragging] = useState(false);
    const sliderRef = useRef<HTMLDivElement>(null);

    const volumeMouseDown = (e: any) => {
        e.preventDefault();
        setIsVolumeDragging(true);
    };

    const handleMouseUp = () => {
        setIsVolumeDragging(false);
    };

    const handleVolumeClick = (e: any) => {
        if (!sliderRef.current) return;
        const boundingRect = sliderRef.current.getBoundingClientRect();
        const offsetX = (e.touches ? e.touches[0].clientX : e.clientX) - boundingRect.left;
        const width = boundingRect.width;
        const newVolume = Math.min(100, Math.max(0, Math.round(offsetX / width * 100))); // Ensure newVolume is between 0 and 100  
        handleVolumeChange(newVolume);
    };

    const volumeMouseMove = (e: any) => {
        if (isVolumeDragging) {
            const boundingRect = sliderRef.current!.getBoundingClientRect();
            const offsetX = (e.touches ? e.touches[0].clientX : e.clientX) - boundingRect.left;
            const width = boundingRect.width;
            const newVolume = Math.min(100, Math.max(0, Math.round(offsetX / width * 100))); // Ensure newVolume is between 0 and 100  
            handleVolumeChange(newVolume);
        }
    };

    useEffect(() => {
        document.addEventListener('mousemove', volumeMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('touchmove', volumeMouseMove);
        document.addEventListener('touchend', handleMouseUp);
        return () => {
            document.removeEventListener('mousemove', volumeMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('touchmove', volumeMouseMove);
            document.removeEventListener('touchend', handleMouseUp);
        };
    }, [isVolumeDragging]);

    return (
        <div className={styles.volumeControl}>
            <img src={VolumeControlIcon} className={styles.volumeIcon} />
            <div
                className={styles.customVolumeControl}
                ref={sliderRef}
                onClick={handleVolumeClick}
                onMouseDown={volumeMouseDown}
                onTouchStart={volumeMouseDown}
            >
                <div className={styles.volumeLevel} style={{ width: `${volume}%` }}></div>
            </div>
            <div className={styles.volumeNum}>
                {volume}
            </div>
        </div>
    )
}

export default MusicVolume;