import { useState } from 'react';
import styles from './shareCyberAvatar.module.less';
import DownloadIcon from '@/assets/img/share_page/download_cyber.svg';
import { useMediaQuery } from 'react-responsive';
import CyberAvatarInfo from '@/base/ShareData/CyberAvatarInfo';

interface ShareCyberAvatarProps {
    cyberAvatarInfo: CyberAvatarInfo;
}

const ShareCyberAvatar: React.FC<ShareCyberAvatarProps> = ({ cyberAvatarInfo }) => {
    // 使用 useMediaQuery 钩子来检测屏幕宽度  
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isNotMobile = useMediaQuery({ query: '(min-width: 769px)' });
    const [isDownload, setIsDownload] = useState(false);

    const handleDownload = async () => {
        if (!cyberAvatarInfo.avatarUrl) return;
        if (isDownload) return;
        setIsDownload(true);
        // try {
        //     setIsDownload(true);
        //     const response = await fetch(cyberAvatarInfo.avatar_url);
        //     if (!response.ok) {
        //         message.error('下载失败，请重试');
        //         throw new Error('Network response was not ok');
        //     }

        //     const blob = await response.blob();

        //     // 创建一个 URL 对象  
        //     const url = URL.createObjectURL(blob);

        //     // 创建一个 a 标签并设置 href 和 download 属性  
        //     const a = document.createElement('a');
        //     a.href = url;
        //     a.download = 'avatar.png'; // 设置下载的文件名 

        //     // 触发点击事件  
        //     document.body.appendChild(a);
        //     a.click();
        //     // 清除 URL 对象  
        //     URL.revokeObjectURL(url);
        //     document.body.removeChild(a);
        // } catch (error) {
        //     console.error('Download failed:', error);
        // } finally {
        //     setIsDownload(false);
        // }
        const a = document.createElement('a');
        a.href = cyberAvatarInfo.avatarUrl;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setIsDownload(false);
    }
    return (
        <div className={styles.page}>
            {isNotMobile && <div className={styles.tabletDesktopLayout}>
                <div className={styles.content}>
                    <div className={styles.avatarBox}>
                        <img src={cyberAvatarInfo.avatarUrl} className={styles.avatar} />
                    </div>
                    <div className={styles.textArea}>
                        <div className={styles.name}>{cyberAvatarInfo.name}</div>
                        <div className={styles.tagline}>{cyberAvatarInfo.tagline}</div>
                    </div>
                </div>
                <div className={styles.operationBox}>
                    <div className={styles.downloadBtn} onClick={handleDownload}>
                        <img src={DownloadIcon} className={styles.btnIcon} />
                        <span className={styles.btnText}>
                            {isDownload ? '下载中...' : '下载'}
                        </span>
                    </div>
                </div>
            </div>}
            {
                isMobile && <div className={styles.mobileLayout}>
                    <div className={styles.containerBox}>
                        <div className={styles.avatarBox}>
                            <img src={cyberAvatarInfo.avatarUrl} className={styles.avatar} />
                        </div>
                        <div className={styles.name}>
                            <div className={styles.nameText}>{cyberAvatarInfo.name}</div>
                        </div>
                        <div className={styles.tagline}>
                            <div className={styles.taglineText}>{cyberAvatarInfo.tagline}</div>
                        </div>
                        <div className={styles.downloadBtn} onClick={handleDownload}>
                            <img src={DownloadIcon} className={styles.btnIcon} />
                            <span className={styles.btnText}>
                                {isDownload ? '下载中...' : '下载'}
                            </span>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default ShareCyberAvatar;
