import styles from './changeViewLayout.module.less'
import { Radio } from 'antd'
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';

const ChangeViewLayout: React.FC = observer(() => {
    const { userInfoStore } = useStores();

    const changeView = (e: any) => {
        userInfoStore.setUserInfoData({ userView: e.target.value });
        localStorage.setItem('userView', e.target.value);
    }

    return (
        <>
            <div className={styles.userViewTab}>
                <Radio.Group onChange={changeView} value={userInfoStore.userInfoData.userView} buttonStyle="solid">
                    <Radio.Button value='teacher'>老师</Radio.Button>
                    <Radio.Button value='student'>学生</Radio.Button>
                </Radio.Group>
            </div>
        </>
    )
})
export default ChangeViewLayout;
