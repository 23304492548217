import { useRef, useState, useEffect } from 'react';
import PlayIcon from '@/assets/img/playAudio.svg';
import PauseIcon from '@/assets/img/pauseAudio.svg';
import styles from './audioPlayer.module.less';
import PropTypes from 'prop-types';
import mixpanel from 'mixpanel-browser';

const AudioPlayer = ({ audioSrc, isPlaying, setIsPlaying }) => {
    const audioRef = useRef(null); // 创建引用以方便访问audio元素
    const [progress, setProgress] = useState(0); // 管理进度条
    const [formattedCurrentTime, setFormattedCurrentTime] = useState("0:00");
    const [formattedDuration, setFormattedDuration] = useState("0:00");
    const togglePlayPause = () => {
        const audio = audioRef.current;
        if (isPlaying) {
            if(import.meta.env.PROD) {
                mixpanel.track('audio_pause',
                    {
                        'audioSrc': audioSrc,
                        'playerType': "medium"
                    });
            }
            audio.pause();
        } else {
            if(import.meta.env.PROD) {
                mixpanel.track('audio_play',
                    {
                        'audioSrc': audioSrc,
                        'playerType': "medium"
                    });
            }
            audio.play();
        }
        setIsPlaying(!isPlaying);
    };
    const handleProgressChange = (e) => {
        const value = e.target.value; // 获取当前的进度条值
        const audio = audioRef.current;
        if (audio) {
            const newTime = (audio.duration / 100) * value; // 根据进度条的百分比计算新时间
            audio.currentTime = newTime; // 更新音频当前时间
            setProgress(Math.round((newTime / audio.duration) * 100)); // 更新进度状态为整数
            setFormattedCurrentTime(formatTime(newTime));
        }
    };
    function formatTime(timeInSeconds) {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = Math.floor(timeInSeconds % 60);
        const paddedSeconds = seconds < 10 ? `0${seconds}` : seconds;
        return `${minutes}:${paddedSeconds}`;
    }

    useEffect(() => {
        const audio = audioRef.current;
        const updateProgress = () => {
            const { currentTime, duration } = audio;
            if (isNaN(duration) || isNaN(currentTime)) {  
                setProgress(0);  
            } else {  
                setProgress((currentTime / duration) * 100);  
            }  
        }
        const handleEnded = () => {
            setIsPlaying(false);
        }

        audio.addEventListener('timeupdate', updateProgress);
        audio.addEventListener('ended', handleEnded);

        // 清理监听器
        return () => {
            audio.removeEventListener('timeupdate', updateProgress);
            audio.removeEventListener('ended', handleEnded);
        };
    }, [isPlaying]);
    useEffect(() => {
        const audio = audioRef.current;

        const updateProgress = () => {
            const { currentTime, duration } = audio;
            if (isNaN(duration) || isNaN(currentTime)) {  
                setProgress(0);  
                setFormattedCurrentTime("0:00");  
            } else {  
                setProgress((currentTime / duration) * 100);  
                setFormattedCurrentTime(formatTime(currentTime)); // 确保这里更新了 formattedCurrentTime  
            }  
        }

        audio.addEventListener('timeupdate', updateProgress);

        // 设置初始值
        const setAudioData = () => {
            if (isNaN(audio.duration)) {  
                setFormattedDuration("0:00");  
            } else {  
                setFormattedDuration(formatTime(audio.duration)); // 当音频的 duration 变得可用时设置总时长  
            } 
        };

        if (audio.readyState > 0) {
            // 如果audio元素在这个effect运行时已经加载好了（例如，快速切换组件），立即设置时长
            setAudioData();
        } else {
            audio.addEventListener('loadedmetadata', setAudioData);
        }

        // 清理监听器
        return () => {
            audio.removeEventListener('timeupdate', updateProgress);
            audio.removeEventListener('loadedmetadata', setAudioData);
        };
    }, []); // 由于我们只监听事件，依赖列表为空数组

    useEffect(() => {
        const audio = audioRef.current;

        if (audioSrc) {
            audio.src = audioSrc;
            audio.load(); // 明确加载新的音频源  
        }
    }, [audioSrc]);
    return (
        <>
            <div className={styles.mediumAudioPlayer}>
                <div className={styles.playIcon}>
                    <img
                        src={isPlaying ? PauseIcon : PlayIcon}
                        alt={isPlaying ? "pause" : "play"}
                        onClick={togglePlayPause} />
                </div>
                <div className={styles.playArea}>
                    <div className={styles.progressBarContainer}>
                        <span className={styles.formattedCurrentTime}>{formattedCurrentTime}</span>
                        <input
                            type="range"
                            value={progress}
                            onChange={handleProgressChange}
                            min="0"
                            max="100"
                            className={styles.progressBar}
                        />
                        <span className={styles.formattedDuration}>{formattedDuration}</span>
                    </div>
                </div>
            </div>
            <audio ref={audioRef} className={styles.audio} >
                <source src={audioSrc} type="audio/mpeg" />
                Your browser does not support the audio element.
            </audio>
        </>
    )
}

export default AudioPlayer;

AudioPlayer.propTypes = {
    audioSrc: PropTypes.string,
    isPlaying: PropTypes.bool.isRequired,
    setIsPlaying: PropTypes.func.isRequired,
}; 