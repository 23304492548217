import { BlockData, BlockTraceData } from "./BlockData";
import DataDefinition from "./DataDefinition";

class SectionData extends DataDefinition {
    id: number;
    name: string;
    @Reflect.metadata('design:type', Array)
    @Reflect.metadata('array_elment', BlockData)
    blks: BlockData[];
    extras: Record<string, any>;

    constructor({
        id = 0,
        name = '',
        blks = [],
        extras = {}
    }: Partial<SectionData> = {}) {
        super();
        this.allowedFields = ['id', 'name', 'blks', 'extras'];
        this.id = id;
        this.name = name;
        this.blks = blks;
        this.extras = extras;
    }
}


class SectionAddBlockRequest extends DataDefinition {
    sectionId: number;
    @Reflect.metadata('design:type', BlockData)
    block: BlockData;
    offset?: number;

    constructor({
        sectionId = 0,
        block = new BlockData(),
        offset = 0
    }: Partial<SectionAddBlockRequest> = {}) {
        super();
        this.allowedFields = ['sectionId', 'block', 'position'];
        this.sectionId = sectionId;
        this.block = block;
        this.offset = offset;
    }
}


class SectionAddBlockTraceRequest extends DataDefinition {
    sectionTraceId: number;
    @Reflect.metadata('design:type', BlockTraceData)
    blockTrace: BlockTraceData;
    offset: number;

    constructor({
        sectionTraceId = 0,
        blockTrace = new BlockTraceData(),
        offset = 0
    }: Partial<SectionAddBlockTraceRequest> = {}) {
        super();
        this.allowedFields = ['sectionTraceId', 'blockTrace', 'offset'];
        this.sectionTraceId = sectionTraceId;
        this.blockTrace = blockTrace;
        this.offset = offset;
    }


}

export { SectionData, SectionAddBlockRequest, SectionAddBlockTraceRequest };