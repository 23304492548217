import { useEffect, useState } from 'react';
import service from '@/services/axios';
import styles from './weChatAuth.module.less';

const WeChatAuth = () => {
    const [loading, setLoading] = useState(false);

    const weChatLoginAuth = async (code, state) => {
        setLoading(true);
        try {
            const res = await service.get('/wechat_auth', {
                params: {
                    code,
                    state
                }
            });
            setLoading(false);
            // 通知父窗口redirect  
            window.parent.postMessage({ token: res.data.access_token, redirect: '/' }, '*');
        } catch (error) {
            console.log('error:', error);
        }
    }
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const state = urlParams.get('state');

        if (code && state) {
            weChatLoginAuth(code, state);
        }
    }, [])

    return (
        <div className={styles.weChatAuthBox}>
            <div className={styles.loadingBox}>
                <div className={styles.loadingIcon}></div>
                <div className={styles.loadingText}>
                    登录中...
                </div>
            </div>
        </div>
    )
}

export default WeChatAuth;