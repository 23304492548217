import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, message, Switch } from 'antd';
import service from '@/services/axios';
import styles from './createBlock.module.less'
import { cloneDeep } from 'lodash';
import BlockEditor from '@/components/BlockEditor/BlockEditor';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import { BlockData } from '@/base/BlockData';
import { ElementData, ElementContent } from '@/base/ElementData/ElementData';
import { SectionAddBlockRequest } from '@/base/SectionData';


interface CreateBlockProps {
    createBlockModalOpen: boolean;
    setCreateBlockModalOpen: (open: boolean) => void;
    getBlockList: () => void;
    sectionId: number;
    scrollBottom: () => void;
    editingBlock: BlockData | null;
    optionType: number;
    dataNow: number;
    blockList: any[];
    currentBlockId: number;
};

const CreateBlock: React.FC<CreateBlockProps> = observer(({
    createBlockModalOpen,
    setCreateBlockModalOpen,
    getBlockList,
    sectionId,
    scrollBottom,
    editingBlock,
    optionType,
    dataNow,
    blockList,//全部block的数组
    currentBlockId,//要在下方插入block的当前blockId
}) => {
    const [block, setBlock] = useState<BlockData | null>(cloneDeep(editingBlock));
    
    interface EditorRef {
        clearFocusedItem: () => void;
    }
    
    const editorRef = useRef<EditorRef>(null);
    const { lectureStore } = useStores();

    //清空选中的元素
    const clearFocusedItem = () => {
        if (editorRef.current) {
            editorRef.current.clearFocusedItem();
        }
    }

    // 根据 currentBlockId 在 blockList 中找到相应的索引
    function getBlockIndex(blockList: BlockData[], currentBlockId: number) {
        return blockList.findIndex(block => block.id === currentBlockId);
    }


    const handleOk = async () => {
        if (block!.blockInfo.length === 0) {
            message.error('请添加内容');
            return;
        }

        let serviceUrl = '';
        let sectionAddBlockParams: any = null;
        let blockParams: BlockData | null = null;
        // TODO: 需要看这个newBlockInfo的影响
        //去掉blockInfo中的isFocus和id
        //const newBlockInfo = blockInfo.map(element => {
        //    const { isFocus, ...rest } = element;
        //    return rest;
        //});

        switch (optionType) {
            case 1: {
                serviceUrl = '/section_add_blk';
                sectionAddBlockParams = new SectionAddBlockRequest({
                    sectionId: sectionId,
                    block: block!,
                    offset: -1,
                });

                break;
            }
            case 2: {
                // 这个是编辑
                serviceUrl = '/block';
                blockParams = cloneDeep(block);
                break;
            }
            case 3: {
                serviceUrl = '/section_add_blk';
                // 根据 currentBlockId 在 blockList 中找到相应的索引  
                const blockIndex = getBlockIndex(blockList, currentBlockId) + 1;
                console.log('case 3 blockIndex', blockIndex);
                sectionAddBlockParams = new SectionAddBlockRequest({
                    sectionId: sectionId,
                    block: block!,
                    offset: blockIndex
                });
                break;
            }
            case 4: {
                serviceUrl = '/section_add_blk';
                // 根据 currentBlockId 在 blockList 中找到相应的索引  
                const blockIndex = getBlockIndex(blockList, currentBlockId) + 1;
                sectionAddBlockParams = new SectionAddBlockRequest({
                    sectionId: sectionId,
                    block:  block!,
                    offset: blockIndex
                });
                break;
            }
            default:
                break;
        }

        
        
        let res: any = null;
        if(optionType === 2)
        {
            console.log('blockParams', blockParams, blockParams!.toJSON());
            res = await service.put(serviceUrl, blockParams!.toJSON());
        }
        else {
            console.log('sectionAddBlockParams', sectionAddBlockParams, sectionAddBlockParams.toJSON());
            res = await service.put(serviceUrl, sectionAddBlockParams.toJSON());
        }

        if (res.status === 'success') {
            setBlock(null);
            setCreateBlockModalOpen(false);
            clearFocusedItem();
            lectureStore.setBlock(null);
            await getBlockList();

            if (optionType !== 2 && optionType !== 3) {
                scrollBottom();
            }
        }
    };



    const handleCancel = () => {
        setBlock(null);
        setCreateBlockModalOpen(false);
        clearFocusedItem();
        lectureStore.setBlock(null);
    };

    useEffect(() => {
        if (optionType === 2) {
            const blockCopy = cloneDeep(editingBlock)
            setBlock(blockCopy);
        } else if (optionType === 1 || optionType === 3 || optionType === 4) {
            setBlock(new BlockData());
        }
    }, [editingBlock, optionType, dataNow])
    return (
        <div className={styles.createBlockBox}>
            <Modal
                className={styles.createBlockModal}
                open={createBlockModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            // width="31.25rem"
            >
                <BlockEditor
                    block={block!}
                    setBlock={setBlock}
                    ref={editorRef}
                />
            </Modal>
        </div>
    );
});

export default CreateBlock;
